import {
  http,
} from 'msw'

import documentTypes from '@fixtures/document-types.json'
import insurers from '@fixtures/insurers.json'
import coverages from '@fixtures/coverages.json'
import estimation from '@fixtures/offers.json'
import facilities from '@fixtures/facility-types.json'
import insurerMerOptions from '@fixtures/insurer-mer-options.json'
import user from '@fixtures/user.json'

const handlers = [
  http.get('*/api/home/estimation/:estimationId/offers', () => {
    return new Response(JSON.stringify(estimation), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/user/:userId', () => {
    return new Response(JSON.stringify(user), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.patch('*/api/user/:userId', () => {
    return new Response(null, {
      status: 204,
    })
  }),
  http.get('*/api/home/document-types', () => {
    return new Response(JSON.stringify(documentTypes), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/home/coverages', () => {
    return new Response(JSON.stringify(coverages), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/insurers', () => {
    return new Response(JSON.stringify(insurers), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/home/facilities', () => {
    return new Response(JSON.stringify(facilities), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.get('*/api/phone*', () => {
    return new Response(null, {
      status: 200,
    })
  }),
  http.get('*/api/insurers/*/mer-options', () => {
    return new Response(JSON.stringify(insurerMerOptions), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }),
  http.post('*/api/home/estimation/*/offers/*/mer/*', () => {
    return new Response(null, {
      status: 201,
    })
  })
]

export {
  handlers
}
