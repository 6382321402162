import {
  datadogRum,
} from '@datadog/browser-rum'

if (import.meta.env.VITE_ENV === 'production') {
  datadogRum.init({
    applicationId: '382e9007-fe9d-46d4-a370-ed1767e1b470',
    clientToken: 'pub176c52ef443e42fe3bb62a7c1f9b0749',
    site: 'datadoghq.com',
    service: 'home-insurance',
    env: import.meta.env.VITE_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
