import TagManager from 'react-gtm-module'

import {
  isProduction,
} from '@root/util/env'

interface GAPurchaseItem {
  item_id: string;
  item_name?: string;
  item_brand?: string;
  price?: number;
  index?: number;
}

interface GAPurchaseEvent {
  event: 'purchase';
  ecommerce: {
    transaction_id: string;
    value: number;
    currency: 'USD';
    items: GAPurchaseItem[],
  }
}

interface Item {
  id: string;
  name?: string;
  brand?: string;
}

export const sendPurchaseEvent = (value: number, transactionId: string, item: Item, position: number | null = null) => {
  const purchaseEvent: GAPurchaseEvent = {
    event: 'purchase',
    ecommerce: {
      transaction_id: transactionId,
      value,
      currency: 'USD',
      items: [],
    },
  }

  const purchaseItem: GAPurchaseItem = {
    item_id: item.id,
    item_name: item?.name,
    item_brand: item?.brand,
    price: value,
  }

  if (position) {
    purchaseItem.index = position
  }

  purchaseEvent.ecommerce.items.push(purchaseItem)

  if (!isProduction() || !import.meta.env.VITE_GTM_ID) {
    // eslint-disable-next-line no-console
    console.log('GTM Purchase', purchaseEvent)

    return
  }

  TagManager.dataLayer({
    dataLayer: purchaseEvent,
  })
}
